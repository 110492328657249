import React from "react";

const Loading = () => {
  return (
    <div className="loadingio-spinner-eclipse-2by998twmg8">
      <div className="ldio-yzaezf3dcmj">
        <div></div>
      </div>
    </div>
  );
};

export default Loading;
